body {
    margin: 0;
    font-family: 'Evolve' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -webkit-text-size-adjust: none;
}

html, body, #root {
    height: 100%;
    width: 100%;
}

h1, h2, h3, h4 {
    font-family: 'Coolvetica' !important;

}

input {
    font-family: 'Evolve' !important;
}


@font-face {
    font-family: "Coolvetica";
    src: url("assets/fonts/coolvetica.ttf") format("truetype");
}

@font-face {
    font-family: "Evolve";
    src: url("assets/fonts/evolve.otf") format("opentype");
}

@font-face {
    font-family: "Longhaul";
    src: url("assets/fonts/longhaul.ttf") format("truetype");
}


@keyframes dropDown {
    0% {top: -100px}
    100% {top: 30px}
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}